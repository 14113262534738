import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import theme from "theme";
import FullPagePhotoSection from "../../../atoms/FullPagePhotoSection/FullPagePhotoSection";
import AnimatedContent from "./AnimatedContent";

const SocialMediaSection = React.forwardRef(
  ({ name, active, sectionOffset }, ref) => {
    const data = useStaticQuery(graphql`
      query {
        imageWall: file(relativePath: { eq: "wall2.jpg" }) {
          ...fluidImageBgDesktop
        }
      }
    `);
    return (
      <FullPagePhotoSection
        ref={ref}
        id={name}
        imageData={[
          theme.gradients.retroOverlayInverted,
          data.imageWall.childImageSharp.fluid,
        ]}
      >
        <AnimatedContent
          name={name}
          active={active}
          sectionOffset={sectionOffset}
        />
      </FullPagePhotoSection>
    );
  }
);

export default SocialMediaSection;
