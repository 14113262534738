import styled, { css } from "styled-components";
import { transparentize } from "polished";

const crossSectionStyles = css`
  bottom: 0;
  height: ${({ offsetHeight }) => 2 * offsetHeight}%;
  background-image: ${({ color }) => `linear-gradient(
    180deg,
    ${transparentize(1, color)} 0%,
    ${color} 50%,
    ${transparentize(1, color)} 100%
  )`};
  transform: translateY(50%);
`;

const endSectionStyles = css`
  bottom: 0;
  height: ${({ offsetHeight }) => offsetHeight}%;
  background-image: ${({ color }) => `linear-gradient(
    180deg,
    ${transparentize(1, color)} 0%,
    ${color} 100%
  )`};
`;

const BackgroundTransition = styled.div`
  position: absolute;
  pointer-events: none;
  width: 100vw;
  z-index: 1;
  ${({ crossSection }) =>
    crossSection ? crossSectionStyles : endSectionStyles}
`;

BackgroundTransition.defaultProps = {
  offsetHeight: 15,
};

export default BackgroundTransition;
