import React, { useContext } from "react";
import styled from "styled-components";
import { LoadingContext } from "utils/decorators/LoadingProvider";
import { StyledBackgroundImage, Section } from "./style";

const FullPagePhotoSection = React.forwardRef(
  ({ children, imageData, active, stickyHeight, id, ...props }, ref) => {
    const { registerNewImage, setImageLoaded } = useContext(LoadingContext);
    return (
      <Section stickyHeight={stickyHeight} ref={ref}>
        <StyledBackgroundImage
          {...props}
          id={id}
          Tag="div"
          active={active}
          fluid={imageData}
          onLoad={() => setImageLoaded(id)}
          onStartLoad={() => registerNewImage(id)}
          critical
        >
          {children}
        </StyledBackgroundImage>
      </Section>
    );
  }
);

export default styled(FullPagePhotoSection)`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;
