import React, { useContext } from "react";
import PropTypes from "prop-types";
import PageRouteContext from "pageRoutes/PageRouteContext";
import { indexRoutes as routes } from "pageRoutes/routes";
import { animated } from "react-spring";
import useContentStagger from "utils/hooks/useContentStagger";
import { ScrollLink } from "react-scroll";
import { TextContainer } from "./style";
import Button from "components/atoms/Button";
import { Parallax } from "react-scroll-parallax";

const AnimatedScrollButton = animated(ScrollLink(Button));
const AnimatedContent = React.memo(({ sectionOffset, name }) => {
  const { setActiveRoute } = useContext(PageRouteContext);
  const stylePropTrail = useContentStagger(
    name,
    sectionOffset,
    3,
    {
      offsetShow: 300,
      offsetHide: 600,
      areaShow: 1000,
    },
    true
  );
  return (
    <TextContainer>
      <div>
        <Parallax y={[0, 9]} tagOuter="div">
          <animated.h2 style={stylePropTrail[0]}>Cliff House</animated.h2>
        </Parallax>
        <Parallax y={[0, 7]} tagOuter="div">
          <animated.h6 style={stylePropTrail[1]}>Stuttgart, GER</animated.h6>
        </Parallax>
      </div>
      <Parallax y={[0, 5]} tagOuter="div">
        <AnimatedScrollButton
          onClick={() => setActiveRoute(routes.contact.name)}
          to={routes.contact.name}
          variant="outline"
          style={stylePropTrail[2]}
          {...routes.contact.animationProps}
        >
          Book Us
        </AnimatedScrollButton>
      </Parallax>
    </TextContainer>
  );
});

AnimatedContent.propTypes = {
  active: PropTypes.bool,
  sectionOffset: PropTypes.number,
  name: PropTypes.string,
};

export default AnimatedContent;
