import React from "react";
import PropTypes from "prop-types";
import { animated } from "react-spring";
import useContentStagger from "utils/hooks/useContentStagger";
import SpotifyFollowEmbed from "./SpotifyFollowEmbed";
import SpotifyTrackEmbed from "./SpotifyTrackEmbed";
import {
  HeadlineContainer,
  MainContainer,
  AmazonPromotionText,
  AmazonImg,
} from "./style";
import AmazonIcon from "images/amazonMusic.png";

const AnimatedSpotifyTrackEmbed = animated(SpotifyTrackEmbed);
const AnimatedContent = React.memo(({ sectionOffset, name }) => {
  const stylePropTrail = useContentStagger(name, sectionOffset, 2, {
    offsetShow: 300,
    offsetHide: 550,
    areaShow: 300,
  });
  return (
    <MainContainer>
      <HeadlineContainer style={stylePropTrail[0]}>
        <h2>Music</h2>
        <SpotifyFollowEmbed />
      </HeadlineContainer>
      <AnimatedSpotifyTrackEmbed style={stylePropTrail[1]} />
      <AmazonPromotionText>
        <p>Also available on </p>
        <AmazonImg src={AmazonIcon} style={{ height: "2rem" }} />
      </AmazonPromotionText>
    </MainContainer>
  );
});

AnimatedContent.propTypes = {
  active: PropTypes.bool,
  sectionOffset: PropTypes.number,
  name: PropTypes.string,
};

export default AnimatedContent;
