import React from "react";
import {
  ContactViewContainer,
  ContactForm,
  Text,
  TextInputs,
  FormFooter,
  TextInputsFirstRow,
} from "./style";
import useContactForm from "utils/hooks/useContactForm";
import Input from "components/atoms/Input";
import Button from "components/atoms/Button";
import Link from "components/atoms/Link";

const inputMeta = {
  name: { type: "text", placeholder: "Name" },
  email: { type: "mail", placeholder: "E-Mail" },
  message: { type: "text", placeholder: "Message" },
};

const initialState = {
  email: "",
  message: "",
  name: "",
};

const ContactSection = React.forwardRef(({ name }, ref) => {
  const [inputs, status, handleInputChange, handleSubmit] = useContactForm(
    initialState
  );
  return (
    <ContactViewContainer id={name} ref={ref}>
      <h2>Get in Contact</h2>
      <ContactForm
        onSubmit={handleSubmit}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <TextInputs>
          <TextInputsFirstRow>
            <Input
              {...inputMeta.name}
              name="name"
              onChange={handleInputChange}
              required
              value={inputs.name}
            />
            <Input
              {...inputMeta.email}
              type="email"
              name="email"
              onChange={handleInputChange}
              required
              value={inputs.email}
            />
          </TextInputsFirstRow>
          <Input
            {...inputMeta.message}
            name="message"
            onChange={handleInputChange}
            required
            value={inputs.message}
          />
        </TextInputs>
        <FormFooter>
          <Button
            type="submit"
            variant="outline"
            disabled={!status.valid || status.submitting}
          >
            {!status.submitting
              ? !status.submitted
                ? "Submit"
                : "Submitted"
              : "Submitting..."}
          </Button>
          <Text>
            Or reach us at{" "}
            <Link href="mailto:management[at]cliffhouse.com">
              management[at]cliffhouse.com
            </Link>
          </Text>
        </FormFooter>
      </ContactForm>
      {status.info.error && (
        <div className="error">Error: {status.info.msg}</div>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
    </ContactViewContainer>
  );
});

export default ContactSection;
