import styled, { css } from "styled-components";
import BackgroundImage from "gatsby-background-image";

const activeStyles = css`
  position: sticky !important;
  top: 0;
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  ${({ active }) => (active ? activeStyles : `position: relative;`)}
`;

export const Section = styled.section`
  height: calc(100vh + ${({ stickyHeight }) => stickyHeight});
`;
