import React, { useContext } from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import GradientTransition from "components/atoms/GradientTransition";
import { AboutViewContainer, StyledParallaxLayer } from "./style";
import { useStaticQuery, graphql } from "gatsby";
import AnimatedContent from "./AnimatedContent";
import { LoadingContext } from "utils/decorators/LoadingProvider";

const AboutSection = React.forwardRef(
  ({ name, sectionOffset, active }, ref) => {
    const { registerNewImage, setImageLoaded } = useContext(LoadingContext);
    const data = useStaticQuery(graphql`
      query {
        CrowdLayer1: file(relativePath: { eq: "CrowdLayer1.png" }) {
          ...fluidImageBgDesktop
        }
        CrowdLayer2: file(relativePath: { eq: "CrowdLayer2.png" }) {
          ...fluidImageBgDesktop
        }
        CrowdLayer3: file(relativePath: { eq: "CrowdLayer3.png" }) {
          ...fluidImageBgDesktop
        }
      }
    `);
    return (
      <AboutViewContainer ref={ref} id={name}>
        <StyledParallaxLayer y={[-3, 20]}>
          <Img
            fluid={data.CrowdLayer3.childImageSharp.fluid}
            id="CrowdLayer3"
            onLoad={() => setImageLoaded("CrowdLayer3")}
            onStartLoad={() => registerNewImage("CrowdLayer3")}
            loading="eager"
          />
        </StyledParallaxLayer>
        <StyledParallaxLayer y={[-3, 10]}>
          <Img
            fluid={data.CrowdLayer2.childImageSharp.fluid}
            id="CrowdLayer2"
            onLoad={() => setImageLoaded("CrowdLayer2")}
            onStartLoad={() => registerNewImage("CrowdLayer2")}
            loading="eager"
          />
        </StyledParallaxLayer>
        <StyledParallaxLayer y={[0, 0]}>
          <Img
            fluid={data.CrowdLayer1.childImageSharp.fluid}
            id="CrowdLayer1"
            onLoad={() => setImageLoaded("CrowdLayer1")}
            onStartLoad={() => {
              registerNewImage("CrowdLayer1");
            }}
            loading="eager"
          />
        </StyledParallaxLayer>
        <AnimatedContent sectionOffset={sectionOffset} name={name} />
        <GradientTransition offsetHeight={11} crossSection color="#000" />
      </AboutViewContainer>
    );
  }
);

AboutSection.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  sectionOffset: PropTypes.number,
};

export default AboutSection;
