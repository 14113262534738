import React, { useContext } from "react";
import DotNavigation, {
  DotLink,
} from "components/organisms/navigation/dotNavigation";
import PageRouteContext from "pageRoutes/PageRouteContext";
import { indexRoutes as routes } from "pageRoutes/routes";

export default function SideNavigation() {
  const { activeRoute, setActiveRoute } = useContext(PageRouteContext);
  return (
    <DotNavigation>
      {Object.keys(routes).map(route => {
        const routeId = routes[route].name;
        return (
          <DotLink
            key={routeId}
            to={routeId}
            active={routeId === activeRoute}
            onSetActive={() => setActiveRoute(routeId)}
            {...routes[route].animationProps}
          >
            {routes[route].linkName}
          </DotLink>
        );
      })}
    </DotNavigation>
  );
}
