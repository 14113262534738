import styled from "styled-components";

export const ContactViewContainer = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: ${_ => _.theme.colors.blueishGray};
  box-sizing: border-box;
  padding: ${({
    theme: {
      layout: { sideMarginInnerDesktop },
    },
  }) => `7rem ${sideMarginInnerDesktop}`};
  @media (max-width: 768px) {
    padding: ${({
      theme: {
        layout: { sideMarginInnerMobile },
      },
    }) => `0 ${sideMarginInnerMobile}`};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactForm = styled.form`
  flex: 1;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-height: 300px;

  input:nth-child(-n + 2) {
    @media (min-width: 576px) {
    }
  }
  input:last-child {
    @media (min-width: 576px) {
    }
  }
`;

export const Text = styled.span`
  color: rgba(255, 255, 255, 0.75);
  flex: 1 0 calc(50% - 1em);
  white-space: nowrap;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 2em;
  margin-top: 0.5em !important;
  a {
    margin-left: 0.3em;
  }
`;

export const TextInputsFirstRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  input {
    margin: 0 0 1.215rem 0;
    width: 100%;
  }
  @media (min-width: 497px) {
    padding: 0 0.5em;
    margin: 0 -1em;
    width: unset;
    input {
      flex: 1 0 20rem;
      margin: 0 0.5em 1.215rem 0.5em;
    }
  }
`;

export const TextInputs = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  input[name="message"] {
    flex: 1;
    width: 100%;
  }
`;

export const FormFooter = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 0 0.5em;
  margin: 1.215rem -1em 0 -1em;
  & > * {
    margin: 0 0.5em;
  }
  button {
    flex: 1 0 calc(50% - 1em);
  }
`;
