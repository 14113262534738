import React, { useEffect, useContext } from "react";
import { ScrollContext } from "utils/decorators/ScrollProvider";
import theme from "theme";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import useScrollIdle from "utils/hooks/useScrollIdle";
import FullPagePhotoSection from "components/atoms/FullPagePhotoSection";
import useMedia from "utils/hooks/useMedia";
import AnimatedContent from "./AnimatedContent";
import GradientTransition from "components/atoms/GradientTransition";
import { MainContainer, ScrollIndicatorStyled } from "./style";

const LandingSection = React.forwardRef(
  ({ name, active, sectionOffset }, ref) => {
    const { registerNewScrollElement } = useContext(ScrollContext);
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      registerNewScrollElement({
        id: name,
        from: 1,
        to: 0,
        upper: sectionOffset,
        lower: sectionOffset + 1000,
        domRef: ref,
        styleProp: "opacity",
      });
    }, []);

    const isSmallScreen = useMedia(["(max-width: 730px)"], [true], false);
    const isIdle = useScrollIdle(5000);
    const { mobileImg, desktopImg } = useStaticQuery(graphql`
      query {
        desktopImg: file(relativePath: { eq: "wall1.jpg" }) {
          ...fluidImageBgDesktop
        }
        mobileImg: file(relativePath: { eq: "walking2.jpg" }) {
          ...fluidImageBgMobile
        }
      }
    `);
    const sectionContent = (
      <>
        <MainContainer>
          <AnimatedContent
            name={name}
            active={active}
            sectionOffset={sectionOffset}
          />
        </MainContainer>
        <ScrollIndicatorStyled show={isIdle} />
        <GradientTransition crossSection color="#02041A" />
      </>
    );

    return isSmallScreen ? (
      <FullPagePhotoSection
        ref={ref}
        id={name}
        active={active}
        stickyHeight="500px"
        imageData={[
          theme.gradients.retroOverlay,
          mobileImg.childImageSharp.fluid,
        ]}
      >
        {sectionContent}
      </FullPagePhotoSection>
    ) : (
      <FullPagePhotoSection
        ref={ref}
        id={name}
        active={active}
        stickyHeight="500px"
        imageData={[
          theme.gradients.retroOverlay,
          desktopImg.childImageSharp.fluid,
        ]}
      >
        {sectionContent}
      </FullPagePhotoSection>
    );
  }
);

LandingSection.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
  sectionOffset: PropTypes.number,
};

export default LandingSection;
