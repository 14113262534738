import React from "react";
import styled from "styled-components";

const uri =
  "https://open.spotify.com/follow/1/?uri=spotify:artist:3niuBt6jngUGu3z7P70WlK&size=detail&theme=dark";
const FollowEmbedStyled = styled.iframe`
  border: none;
  overflow: hidden;
  margin-right: -6rem;
  width: 300px;
  height: 56px;
`;

export default function SpotifyFollowEmbed() {
  return (
    <FollowEmbedStyled
      src={uri}
      scrolling="no"
      frameborder="0"
      allowtransparency="true"
    ></FollowEmbedStyled>
  );
}
