import { useEffect, useRef, useState, Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { ScrollElement } from "react-scroll";

const Section = ({ active, name, children, ...other }) => {
  const sectionRef = useRef(null);
  const [sectionOffset, setSectionOffset] = useState(0);
  useEffect(() => {
    setSectionOffset(sectionRef.current.getBoundingClientRect().top);
  }, []);
  return Children.map(children, child =>
    cloneElement(child, {
      ref: sectionRef,
      sectionOffset,
      active,
      name,
      ...other,
    })
  );
};

Section.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
};

export default ScrollElement(Section);
