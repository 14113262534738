import styled from "styled-components";
import { animated } from "react-spring";

export const HeadlineContainer = styled(animated.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${({
    theme: {
      layout: { sideMarginInnerDesktop },
    },
  }) => `7rem ${sideMarginInnerDesktop}`};
  @media (max-width: 768px) {
    padding: ${({
      theme: {
        layout: { sideMarginInnerMobile },
      },
    }) => `7rem ${sideMarginInnerMobile}`};
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const AmazonPromotionText = styled.span`
  display: inline-flex;
  align-items: center;

  svg {
    margin-left: 0.3em;
  }
`;

export const AmazonImg = styled.img`
  height: 2rem;
  padding-left: 0.2em;
`;
