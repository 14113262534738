import React, { forwardRef } from "react";
import styled from "styled-components";

const TrackEmbedStyled = styled.iframe`
  width: calc(
    100vw - 2 * ${props => props.theme.layout.sideMarginInnerDesktop}
  );
  @media (max-width: 768px) {
    width: calc(
      100vw - 2 * ${props => props.theme.layout.sideMarginInnerMobile}
    );
  }
  height: 5rem;
  border: none;
`;

const SpotifyTrackEmbed = forwardRef((props, ref) => (
  <TrackEmbedStyled
    ref={ref}
    src="https://open.spotify.com/embed/track/1lbShyZszrTR3eVSgT2lIu?theme=white"
    allowtransparency="true"
    allow="encrypted-media"
  />
));

export default SpotifyTrackEmbed;
