import React from "react";
import PropTypes from "prop-types";
import { animated } from "react-spring";
import useContentStagger from "utils/hooks/useContentStagger";
import PressComment from "components/molecules/PressComment";
import {
  MainContainer,
  CommentsContainer,
  AnimatedText,
  MainContentBody,
} from "./style";
import Icon from "components/atoms/Icon";
import CardStack from "components/organisms/CardStack";
import pressComments from "../../../../../content/pressComments.yml";
import { Parallax } from "react-scroll-parallax";

const AnimatedCardStack = animated(CardStack);

const AnimatedContent = React.memo(({ name, sectionOffset }) => {
  const stylePropTrail = useContentStagger(name, sectionOffset, 2, {
    offsetShow: 300,
    offsetHide: 550,
    areaShow: 300,
  });
  const getPressComments = pressComments.pressComments.map(
    ({ text, svgName, ...other }, index) => (
      <PressComment {...other} key={index} logo={<Icon name={svgName} />}>
        {text}
      </PressComment>
    )
  );
  return (
    <MainContainer>
      <Parallax y={[-20, 20]} tagOuter="div">
        <animated.h2 style={stylePropTrail[0]}>About Us</animated.h2>
      </Parallax>
      <MainContentBody>
        <Parallax y={[-10, 10]} tagOuter="div">
          <AnimatedText style={stylePropTrail[1]}>
            <b>Cliff House</b> stands for honest and authentic Pop/Rock with
            influences of Singer/Songwriter and elements of folk. Since 2014,
            the four young men from Schorndorf (Germany) are touring through the
            area of Stuttgart, exclusively with their own-written songs. The
            lyrics include typical daydream-stories, the never ending trouble
            with women or the classic „what if“-question. Clap your hands, get
            into a move or pull out your lighters and enjoy an authentic and
            special atmosphere.{" "}
          </AnimatedText>
        </Parallax>
        <CommentsContainer>
          <AnimatedCardStack
            style={stylePropTrail[1]}
            cardBaseHeight={170.432}
            cardBaseWidth={373}
            zOffset={1}
            interval={8000}
          >
            {getPressComments}
          </AnimatedCardStack>
        </CommentsContainer>
      </MainContentBody>
    </MainContainer>
  );
});

AnimatedContent.propTypes = {
  active: PropTypes.bool,
  sectionOffset: PropTypes.number,
  name: PropTypes.string,
};

export default AnimatedContent;
