import React, { useContext } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { useTransition, animated } from "react-spring";
import Section from "utils/decorators/Section";
import { LoadingContext } from "utils/decorators/LoadingProvider";
import { OverlayStateContext } from "utils/decorators/OverlayStateProvider";
import useScrollPositionTracking from "utils/hooks/useScrollPositionTracking";
import useMedia from "utils/hooks/useMedia";
import PageRouteContext from "pageRoutes/PageRouteContext";
import SEO from "components/seo";
import * as ScrollSections from "components/organisms/scrollSections";
import LoadingOverlay from "components/molecules/LoadingOverlay";
import { MenuOverlayPortal } from "components/organisms/navigation/hamburgerNavigation";
import SideNavigation from "components/organisms/SideNavigation";
import { indexRoutes as routes } from "pageRoutes/routes";

const AnimatedLoadingOverlay = animated(LoadingOverlay);
const IndexPage = () => {
  const { activeRoute } = useContext(PageRouteContext);
  const { shownOverlay } = useContext(OverlayStateContext);
  const { isEverythingLoaded } = useContext(LoadingContext);
  const isSmallScreen = useMedia(["(max-width: 540px)"], [true], false);
  const fadeOutTransitions = useTransition(!isEverythingLoaded, null, {
    from: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 2000 },
  });

  useScrollPositionTracking();
  return (
    <>
      <SEO title="Home" />
      {fadeOutTransitions.map(
        ({ item, key, props }) =>
          item && <AnimatedLoadingOverlay key={key} style={props} />
      )}
      {!isSmallScreen && <SideNavigation />}
      <ParallaxProvider>
        <Section
          name={routes.landing.name}
          active={activeRoute === routes.landing.name}
        >
          <ScrollSections.LandingSection />
        </Section>
        <Section
          name={routes.about.name}
          active={activeRoute === routes.about.name}
        >
          <ScrollSections.AboutSection />
        </Section>
        <Section
          name={routes.music.name}
          active={activeRoute === routes.music.name}
        >
          <ScrollSections.MusicSection />
        </Section>
        <Section
          name={routes.social.name}
          active={activeRoute === routes.social.name}
        >
          <ScrollSections.SocialMediaSection />
        </Section>
        <Section
          name={routes.contact.name}
          active={activeRoute === routes.contact.name}
        >
          <ScrollSections.ContactSection />
        </Section>
        <MenuOverlayPortal
          isVisible={shownOverlay === "menu"}
          id="menu-portal"
        />
      </ParallaxProvider>
    </>
  );
};

export default IndexPage;
