import { useContext, useEffect } from "react";
import { ScrollContext } from "utils/decorators/ScrollProvider";
import { useTrail } from "react-spring";

const springConfIn = { mass: 1, tension: 350, friction: 100 };
const springConfOut = { mass: 1, tension: 430, friction: 50 };
const defaultOffsets = {
  offsetShow: 0,
  offsetHide: 0,
  areaShow: 600,
};

const useContentStagger = (
  scrollAreaName,
  sectionStart,
  numEl,
  offsets = defaultOffsets,
  activeOnInit
) => {
  const { scrollAreas, registerNewScrollArea } = useContext(ScrollContext);
  const { offsetShow, offsetHide, areaShow } = offsets;
  const topThreshold = sectionStart - offsetShow;
  const bottomThreshold = sectionStart + areaShow + offsetHide;
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    registerNewScrollArea({
      id: scrollAreaName,
      upper: topThreshold,
      lower: bottomThreshold,
      active: activeOnInit,
    });
  }, [topThreshold, bottomThreshold]);
  const active = Boolean(
    scrollAreas &&
      scrollAreas[scrollAreaName] &&
      scrollAreas[scrollAreaName].active
  );
  return useTrail(numEl, {
    config: active ? springConfIn : springConfOut,
    from: { opacity: 0, transform: "translateY(1rem)" },
    to: {
      opacity: active ? 1 : 0,
      transform: active ? "translateY(0rem)" : "translateY(1rem)",
    },
    delay: 20,
  });
};

export default useContentStagger;
