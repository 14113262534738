import React from "react";
import Helmet from "react-helmet";
import { default as ReactInstagramEmbed } from "react-instagram-embed";
import styled from "styled-components";
import Icon from "components/atoms/Icon";

const StyledInstagramEmbed = styled(ReactInstagramEmbed)`
  height: 100%;
  overflow: auto;
  max-width: 430px;
  width: calc(50% - 2px);
  @media (max-width: 1100px) {
    width: calc(100% - 2px);
    overflow: hidden;
    height: 54px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  iframe {
    min-width: unset !important;
    float: right;
  }
`;

export const ArrowIcon = styled(Icon)`
  @media (max-width: 1100px) {
    display: none;
  }
`;

export const FollowUsText = styled.p`
  margin: 0 0 0 -3em;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-left: 11%;
`;

const FollowArrowContainer = styled.div`
  height: 3.222rem;
  align-self: center;
  margin-right: 0.2em;
`;

const iframeUri =
  "https://www.instagram.com/p/B0bK-SBIInc/?utm_source=ig_web_options_share_sheet";

export default function InstagramEmbed() {
  return (
    <>
      <Helmet>
        <script async src="https://www.instagram.com/embed.js" />
      </Helmet>
      <Wrapper>
        <StyledInstagramEmbed
          url={iframeUri}
          maxWidth={430}
          className="instaContainer"
        />
        <FollowArrowContainer>
          <FollowUsText>follow us</FollowUsText>
          <ArrowIcon name="arrow" />
        </FollowArrowContainer>
      </Wrapper>
    </>
  );
}
