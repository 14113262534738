import styled, { keyframes } from "styled-components";
import { animated } from "react-spring";
import { Parallax } from "react-scroll-parallax";

const Scaling = keyframes`
  0% { 
    opacity: 0.3;
  }
  50% { 
    opacity: 0.1;
  }
  100% { 
    opacity: 0.3;
  }
`;

export const AnimatedText = styled(animated.p)`
  flex: 1;
  margin-right: 17%;
`;

export const AboutViewContainer = styled.section`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  &:before {
    content: "";
    top: -100%;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.3;
    z-index: -2;
    position: absolute;
    background: ${props => props.theme.gradients.blueRadial};
    transform-origin: 27.14% 36.73%;
    animation: ${Scaling} 5s linear infinite;
  }
  &:after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(180deg, #000000 0%, #333348 100%);
    z-index: -3;
    position: absolute;
  }
  .parallax-outer {
    z-index: -1;
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: ${({
    theme: {
      layout: { sideMarginInnerDesktop },
    },
  }) => `7rem ${sideMarginInnerDesktop}`};

  @media (max-width: 768px) {
    padding: ${({
      theme: {
        layout: { sideMarginInnerMobile },
      },
    }) => `7rem ${sideMarginInnerMobile}`};
  }
  min-height: 100vh;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    margin-bottom: 3rem;
  }
`;

export const MainContentBody = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CommentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  z-index: 1;
`;

export const StyledParallaxLayer = styled(Parallax)`
  position: absolute !important;
  overflow: hidden;
  width: 100%;
  height: 0 !important; /* height gets set through the padding-bottom-hack */
  padding-bottom: 66.666667%;
  bottom: 0;
  .parallax-inner {
    min-width: 600px;
    width: 100%;
    bottom: 0;
    position: absolute;
  }
`;
