import styled, { keyframes } from "styled-components";
import { animated } from "react-spring";

const slideInLeft = keyframes`
  0% { opacity: 0; transform: translateX(40px); } 
  100% { transform: translateX(0); } 
`;

const slideInRight = keyframes`
  0% { opacity: 0; transform: translateX(-40px); }
  100% { transform: translateX(0); } 
`;

export const StackWrapper = styled.div`
  width: ${_ => _.width}px;
  height: ${_ => _.height}px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -3.5rem;
    right: -3.5rem;
    bottom: 0;
  }

  & > a {
    bottom: ${_ => _.cardBaseHeight / 2}px;
  }

  &:hover > a {
    display: flex;
  }

  &:hover > a:first-of-type {
    animation: ${slideInLeft} 200ms;
  }

  &:hover > a:nth-of-type(2) {
    animation: ${slideInRight} 200ms;
  }
`;

export const CardList = styled.ul`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

export const CardStyled = styled(animated.li)`
  background-color: ${({ color }) => color};
  border-radius: 2px;
  position: absolute;
  list-style: none;
  height: 300px;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-top: 20px;
  box-sizing: border-box;
  text-align: center;
  background-size: cover;
`;
