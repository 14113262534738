import styled from "styled-components";

export const CardWrapper = styled.li`
  height: 10.625rem;
  width: 23.3125rem;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 1.625rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardText = styled.p`
  flex: 1;
  font-size: 0.8125rem;
  color: ${_ => _.theme.colors.textDark};
  margin: 0;
`;

export const CardFooter = styled.div`
  display: flex;
  svg {
    display: inline-block;
    height: 2.125rem;
    width: 2.125rem;
    clip-path: circle(50% at 50% 50%);
  }
`;

export const CardTitles = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: stretch;
  margin-left: 1rem;

  p {
    font-size: 0.8125rem;
    margin: 0;
  }
  p:first-child {
    color: ${_ => _.theme.colors.textDark};
    font-weight: 500;
  }
  p:last-child {
    color: ${_ => _.theme.colors.textDarkSecondary};
  }
`;
