import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import FullPagePhotoSection from "components/atoms/FullPagePhotoSection";
import AnimatedContent from "./AnimatedContent";

const MusicSection = React.forwardRef(
  ({ name, active, sectionOffset }, ref) => {
    const data = useStaticQuery(graphql`
      query {
        imageWalking: file(relativePath: { eq: "walkingEdit.png" }) {
          ...fluidImageBgDesktop
        }
      }
    `);
    return (
      <FullPagePhotoSection
        ref={ref}
        id={name}
        imageData={data.imageWalking.childImageSharp.fluid}
      >
        <AnimatedContent
          name={name}
          active={active}
          sectionOffset={sectionOffset}
        />
      </FullPagePhotoSection>
    );
  }
);

MusicSection.propTypes = {
  active: PropTypes.bool,
  name: PropTypes.string,
  sectionOffset: PropTypes.number,
};

export default MusicSection;
