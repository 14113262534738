import React from "react";
import styled, { keyframes } from "styled-components";
import Icon from "components/atoms/Icon";

const gradientKeyframes = keyframes`
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(200%)
  }
`;

const IconContainer = styled.div`
  position: relative;
  width: 30vw;
  min-width: 175px;
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    68deg,
    rgba(20, 21, 26, 0) 0%,
    rgba(20, 21, 26, 0.5) 25%,
    rgba(20, 21, 26, 0.5) 75%,
    rgba(20, 21, 26, 0) 100%
  );
  animation: ${gradientKeyframes} 2s ease-out infinite;
`;

const Overlay = styled.div`
  position: fixed;
  z-index: 9999999999999;
  background: rgb(20, 21, 26);
  color: ${_ => _.theme.colors.textPrimary};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
  }
`;

export default function LoadingOverlay(props) {
  return (
    <Overlay {...props}>
      <IconContainer>
        <Icon name="cliffhouse" />
        <GradientOverlay />
      </IconContainer>
    </Overlay>
  );
}
