import styled, { css, keyframes } from "styled-components";

const mobileArrowOffset = 12; // px
const mobileFadeTime = 3; // % of a period
const mobileHiddenTime = 25; // % of a period
const mobileAnimationSpeed = 7; // seconds

const scrollKeyframesDesktop = keyframes`
0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(36px);
  }
`;
const scrollKeyframesMobile = keyframes`
  0% {
    border-color: rgba(255,255,255,0);
  }
  ${mobileFadeTime}% {
    border-color: rgba(255,255,255,0.5);
  }
  ${100 - mobileHiddenTime - 3 * mobileFadeTime}% {
    border-color: rgba(255,255,255,0.5);
  }
  ${100 - mobileHiddenTime - 2 * mobileFadeTime}% {
    border-color: rgba(255,255,255,0);
  }
  100% {
    border-color: rgba(255,255,255,0);
  }
`;

const scrollKeyframesMobileContainer = keyframes`
  0% {
    transform: translateY(-20px) rotate(45deg) skew(-3deg, -3deg);
  }
  100% {
    transform: translateY(0) rotate(45deg) skew(-3deg, -3deg);
  }
`;

const stylesDesktop = css`
  width: 31px;
  height: 57px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  opacity: 1;
  &,
  &:before {
    position: absolute;
    left: 50%;
  }

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    animation: ${scrollKeyframesDesktop} 1.5s infinite;
  }
`;

const mobileArrowBorderStyles = css`
  border-right: 3px solid rgba(255, 255, 255, 0.5);
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
`;

const stylesMobile = css`
  position: absolute;
  left: 50%;
  width: 20px;
  height: 20px;
  transform: rotate(45deg) skew(-3deg, -3deg);
  transform-origin: center;
  ${mobileArrowBorderStyles}
  animation: ${scrollKeyframesMobile} ${mobileAnimationSpeed}s ease-in-out infinite, ${scrollKeyframesMobileContainer} ${mobileAnimationSpeed}s cubic-bezier(0,.63,0,1.01) infinite;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.66;
    top: ${mobileArrowOffset}px;
    left: ${mobileArrowOffset}px;
    ${mobileArrowBorderStyles}
    animation: ${scrollKeyframesMobile} ${mobileAnimationSpeed}s ease-in-out infinite;
    animation-delay: ${mobileFadeTime * 0.01 * 1.5}s;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.33;
    top: ${2 * mobileArrowOffset}px;
    left: ${2 * mobileArrowOffset}px;
    ${mobileArrowBorderStyles}
    animation: ${scrollKeyframesMobile} ${mobileAnimationSpeed}s ease-in-out infinite;
    animation-delay: ${2 * mobileFadeTime * 0.01 * 1.5}s;
  }
`;

const ScrollIndicator = styled.div`
  @media (min-width: 1024px) {
    ${stylesDesktop}
  }
  @media (max-width: 1024px) {
    ${stylesMobile}
  }
`;

export default ScrollIndicator;
