/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useContext } from "react";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";
import { ScrollContext } from "utils/decorators/ScrollProvider";

const isBrowser = typeof window !== `undefined`;

function getScrollPosition(useWindow) {
  if (!isBrowser) return 0;
  return useWindow
    ? window.scrollY
    : -1 * document.body.getBoundingClientRect().top;
}

function updateScrollElementStyles(scrollElements, scrollY) {
  Object.keys(scrollElements).forEach(id => {
    const { lower, upper, styleProp, domRef, from, to } = scrollElements[id];
    if (
      typeof scrollY.current === "number" &&
      domRef.current instanceof Element
    ) {
      if (scrollY.current < lower && scrollY.current > upper) {
        const range = lower - upper;
        const progress = (scrollY.current - upper) / range;
        const newVal = (to - from) * progress + from;
        domRef.current.style[styleProp] = newVal;
      }
    }
  });
}

function updateScrollAreaBounds(scrollAreas, setActiveOnScrollArea, scrollY) {
  Object.keys(scrollAreas).forEach(name => {
    const { lower, upper, active } = scrollAreas[name];
    if (typeof scrollY.current === "number") {
      if (!active && scrollY.current >= upper && scrollY.current <= lower) {
        setActiveOnScrollArea(name, true);
      } else if (
        active &&
        (scrollY.current < upper || scrollY.current > lower)
      ) {
        setActiveOnScrollArea(name, false);
      }
    }
  });
}

export default function useScrollPositionTracking(useWindow) {
  // scroll trigger format: <scrollAreaName>: {lower: <TriggerInPixelFromTop>, upper:<TriggerInPixelFromTop>, active: false}
  const { scrollAreas, setActiveOnScrollArea, scrollElements } = useContext(
    ScrollContext
  );
  let ticking = false;
  const scrollY = useRef(getScrollPosition(useWindow));
  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }
    let lastScrollPosition = getScrollPosition(useWindow);

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const newScrollPosition = getScrollPosition(useWindow);

          if (lastScrollPosition !== newScrollPosition) {
            lastScrollPosition = newScrollPosition;
            scrollY.current = newScrollPosition;
            // update bounds for each scrollArea
            updateScrollAreaBounds(scrollAreas, setActiveOnScrollArea, scrollY);
            updateScrollElementStyles(scrollElements, scrollY);
          }
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollAreas]);
  // Re-register effect with new scrollAreas if scrollAreas changes
}

useScrollPositionTracking.defaultProps = {
  useWindow: false,
};
