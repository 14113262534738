import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { ScrollLink } from "react-scroll";

const Dot = styled.button`
  pointer-events: all;
  color: ${({
    active,
    theme: {
      colors: { textPrimary },
    },
  }) => (active ? textPrimary : transparentize(1, textPrimary))};

  background-color: transparent;
  border: none;
  padding-right: ${({ active }) => (active ? "2.5rem" : "2rem")};
  opacity: 0.5;
  margin: 1.861rem 0;
  cursor: pointer;
  outline: none;
  transition: opacity 0.2s ease-out, padding-right 0.2s ease-out;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 0.9rem;
    height: 0.9rem;
    border: 0.055em solid #fff;
    background-color: ${({
      active,
      theme: {
        colors: { textPrimary },
      },
    }) => (active ? textPrimary : "transparent")};
    border-radius: 50%;
    transition: background-color 0.2s ease-out;
  }

  &:hover {
    opacity: 1;
    color: ${props => props.theme.colors.textPrimary};
    padding-right: 2.5rem;
    &:after {
      background-color: #fff;
    }
  }
`;
const DotLink = props => <Dot {...props} />;
export default ScrollLink(DotLink);
