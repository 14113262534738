import React from "react";
import PropTypes from "prop-types";
import { animated } from "react-spring";
import useContentStagger from "utils/hooks/useContentStagger";
import { MainContainer, LeftColumn, RightColumn } from "./style";
import InstagramEmbed from "./InstagramEmbed";
import Link from "components/atoms/Link";
import { Parallax } from "react-scroll-parallax";

const AnimatedLink = animated(Link);
const AnimatedContent = React.memo(({ sectionOffset, name }) => {
  const stylePropTrail = useContentStagger(name, sectionOffset, 3, {
    offsetShow: 300,
    offsetHide: 550,
    areaShow: 300,
  });
  return (
    <MainContainer>
      <LeftColumn>
        <animated.h2 style={stylePropTrail[0]}>Social Media</animated.h2>
        <AnimatedLink style={stylePropTrail[1]}>@cliffhousemusic</AnimatedLink>
      </LeftColumn>
      <RightColumn style={stylePropTrail[2]}>
        <Parallax x={[0, -10]} tagOuter="div">
          <InstagramEmbed />
        </Parallax>
      </RightColumn>
    </MainContainer>
  );
});

AnimatedContent.propTypes = {
  active: PropTypes.bool,
  sectionOffset: PropTypes.number,
  name: PropTypes.string,
};

export default AnimatedContent;
