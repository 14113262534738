import React, { Children } from "react";
import styled from "styled-components";

const Stripe = styled.i`
  display: block;
  width: 0.111em;
  opacity: 0.25;
  height: calc(100vh - ${({ nChilds }) => nChilds * 4.832}rem);
  background: linear-gradient(
    rgba(236, 236, 236, 0) 0%,
    rgba(236, 236, 236, 0.75) 8%,
    rgba(236, 236, 236, 0.75) 92%,
    rgba(236, 236, 236, 0) 100%
  );
  margin-right: 0.555rem;
`;

const DotNavigationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: nowrap;
  position: fixed;
  right: ${props => props.theme.layout.sideMarginOuterDesktop};
  height: 100vh;
  z-index: 2;
  margin-right: -0.555rem;
  pointer-events: none;
`;

const DotNavigation = ({ children }) => {
  const nChilds = Children.count(children);
  return (
    <DotNavigationContainer>
      <Stripe nChilds={nChilds} />
      {children}
      <Stripe nChilds={nChilds} />
    </DotNavigationContainer>
  );
};

export default DotNavigation;
