import styled from "styled-components";

const CardStackNavButton = styled.a`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px 0 rgba(0, 0, 0, 0.2);
  color: #454852;
  cursor: pointer;
  display: none; /* gets overwritten on hover */
  height: 2.5rem;
  justify-content: center;
  margin-top: -1.25rem;
  position: absolute;
  user-select: none;
  width: 2.5rem;
  transition: all 0.2s ease;
  z-index: 3;

  span {
    font-size: 0.8125rem;
    line-height: 1;
  }

  &:hover {
    transform: scale(1.3, 1.3);
    color: rgba(0, 0, 0, 0.7);
    background: #fff;
  }

  ${({ type }) =>
    type === "previous"
      ? `left: -3.5rem;
  right: auto;`
      : `left: auto;
  right: -3.5rem`}
`;

export default CardStackNavButton;
