import styled from "styled-components";
import FullPagePhotoSection from "components/atoms/FullPagePhotoSection";
import { animated } from "react-spring";

export const AboutViewContainer = styled(FullPagePhotoSection)`
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2 * ${_ => _.theme.layout.sideMarginOuterDesktop});
    height: calc(100% - 2 * 10.46vh);
    box-sizing: border-box;
    margin: ${_ => `10.46vh ${_.theme.layout.sideMarginOuterDesktop}`};
    background-color: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(4px) grayscale(20%);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(-1);
    background-image: ${_ => _.theme.gradients.retroOverlay};
  }
`;

export const MainContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${({
    theme: {
      layout: { sideMarginInnerDesktop },
    },
  }) => `7rem ${sideMarginInnerDesktop}`};
  @media (max-width: 768px) {
    padding: ${({
      theme: {
        layout: { sideMarginInnerMobile },
      },
    }) => `7rem ${sideMarginInnerMobile}`};
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const TextColumn = styled.div`
  flex: 1;
`;

export const Column = styled.div`
  @media (max-width: 1100px) {
    position: static;
    width: unset;
    top: unset;
    flex: 1;
  }
`;

export const LeftColumn = styled(Column)`
  position: absolute;
  top: 7rem;
  width: 32vw;
`;
export const RightColumn = styled(animated(Column))`
  width: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 1100px) {
    margin-left: 0;
    margin-top: 5%;
  }
`;
