import { useEffect, useRef, useCallback } from "react";

export default function useInterval(callback, period) {
  const savedCallback = useRef();
  const savedIntervalId = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const setNewInterval = useCallback(() => {
    function tick() {
      savedCallback.current();
    }
    if (savedIntervalId.current) {
      clearInterval(savedIntervalId.current);
    }
    if (period) {
      savedIntervalId.current = setInterval(tick, period);
      return () => clearInterval(savedIntervalId.current);
    }
  }, [callback]);
  // Set up the interval.
  useEffect(setNewInterval, [period]);

  return setNewInterval;
}
