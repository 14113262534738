import styled from "styled-components";

const StyledInput = styled.input`
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 2rem;
  color: #fff;
  font-size: 1rem;
  border-radius: 0.125em;
  box-sizing: border-box;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

export default StyledInput;
