import React, { useRef, useState, cloneElement, Children } from "react";
import PropTypes from "prop-types";
import CardStackNavButton from "./CardStackNavButton";
import { StackWrapper, CardList } from "./style";
import { useSprings } from "react-spring";
import useInterval from "utils/hooks/useInterval";

// Returns the interpolated value for a spring index that is
// defined through the two points (0, firstVal) and
// (lastIndex, lastVal)
const getLinearInterpolatedVal = (index, firstVal, lastIndex, lastVal) =>
  ((lastVal - firstVal) / lastIndex) * index + firstVal;

// Returns fitting styles for the items and an updater function (will be named setSprings)
const fn = (newOrder, heightDiff, widthDiff, nChilds, zOffset) => index => {
  const newIndex = newOrder.indexOf(index);
  const lastIndex = nChilds - 1;
  return {
    opacity: getLinearInterpolatedVal(newIndex, 0.5, lastIndex, 1),
    y: heightDiff * newIndex,
    width: 100 - widthDiff * (nChilds - (newIndex + 1)),
    shadow: getLinearInterpolatedVal(newIndex, 7, lastIndex, 14),
    zIndex: zOffset + newIndex,
    immediate: n => n === "zIndex",
    config: {
      mass: 1,
      tension: 390,
      friction: 35,
    },
  };
};

export default function CardStack({
  children,
  cardBaseWidth,
  cardBaseHeight,
  heightDiff,
  widthDiff,
  zOffset,
  interval,
  ...other
}) {
  const childArray = Children.toArray(children);
  const childLength = Children.count(children);
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  // Store indicies as a local ref, this represents the child order
  const order = useRef(childArray.map((_, index) => index));
  // Create springs, each corresponds to an item, controlling its zIndex, width, y-position, etc.
  const [springs, setSprings] = useSprings(
    childLength,
    fn(order.current, heightDiff, widthDiff, childLength, zOffset)
  );

  const handleNext = () => {
    const lastEl = order.current[childLength - 1];
    const newOrder = order.current.slice(0, childLength - 1);
    newOrder.unshift(lastEl);
    // Feed springs new style data, they'll animate the view without causing a single render
    setSprings(fn(newOrder, heightDiff, widthDiff, childLength, zOffset));
    order.current = newOrder;
  };

  const handlePrevious = () => {
    const firstEl = order.current[0];
    const newOrder = order.current.slice(1, childLength);
    newOrder.push(firstEl);
    // Feed springs new style data, they'll animate the view without causing a single render
    setSprings(fn(newOrder, heightDiff, widthDiff, childLength, zOffset));
    order.current = newOrder;
  };

  // Sets up the interval on mount and clears the interval on unmount
  const restartInterval = useInterval(
    handleNext,
    isIntervalRunning ? interval : null
  );

  const handleNextButtonClick = () => {
    restartInterval();
    handleNext();
  };

  const handlePreviousButtonClick = () => {
    restartInterval();
    handlePrevious();
  };

  const newChildren = springs.map(({ shadow, zIndex, width, y, opacity }, i) =>
    cloneElement(childArray[i], {
      key: i,
      style: {
        position: "absolute",
        listStyle: "none",
        zIndex,
        width: width.interpolate(w => `${w}%`),
        margin: width.interpolate(w => `0 ${(100 - w) / 2}%`),
        top: y.interpolate(y => `${y}px`),
        boxShadow: shadow.interpolate(
          s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
        ),
        opacity,
      },
    })
  );

  return (
    <StackWrapper
      width={cardBaseWidth}
      height={cardBaseHeight + (childLength - 1) * heightDiff}
      cardBaseHeight={cardBaseHeight}
      onMouseOver={() => {
        setIsIntervalRunning(false);
      }}
      onMouseLeave={() => {
        setIsIntervalRunning(true);
      }}
      {...other}
    >
      <CardStackNavButton type="previous" onClick={handlePreviousButtonClick}>
        <span>{"❮"}</span>
      </CardStackNavButton>
      <CardList>{newChildren}</CardList>
      <CardStackNavButton type="next" onClick={handleNextButtonClick}>
        <span>{"❯"}</span>
      </CardStackNavButton>
    </StackWrapper>
  );
}

CardStack.propTypes = {
  /* interval in ms to autmatically change the order  */
  interval: PropTypes.number,
  /* height of biggest card in px */
  cardBaseHeight: PropTypes.number,
  /* width of biggest card in px */
  cardBaseWidth: PropTypes.number,
  /* height difference in px */
  heightDiff: PropTypes.number,
  /* width difference in % */
  widthDiff: PropTypes.number,
  /* z-index of lowest card */
  zOffset: PropTypes.number,
};

CardStack.defaultProps = {
  cardBaseWidth: 500,
  cardBaseHeight: 300,
  heightDiff: 12,
  widthDiff: 10,
  zOffset: 0,
};
