import { useState, useEffect, useRef } from "react";
import debounce from "lodash.debounce";

const useScrollIdle = idleTime => {
  const [lastScroll, setLastScroll] = useState(0);
  const tickInterval = useRef();
  useEffect(() => {
    tickInterval.current = setInterval(() => {
      setLastScroll(lastScroll + 1);
    }, 1000);
    const scrollListener = window.addEventListener(
      "scroll",
      debounce(
        () => {
          setLastScroll(0);
        },
        3000,
        { leading: true, trailing: false }
      )
    );
    return () => {
      window.removeEventListener("scroll", scrollListener);
      clearInterval(tickInterval.current);
    };
  });
  return lastScroll > idleTime / 1000;
};

export default useScrollIdle;
