import { useState } from "react";
import encodeUrl from "utils/encodeUrl";

/* eslint-disable no-useless-escape */
const validateEmail = inputs =>
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(inputs.email);
/* eslint-enable */

const validateTouched = inputs =>
  !Object.keys(inputs).some(i => inputs[i] === "");

export default function useContactForm(initialState) {
  const [inputs, setInputs] = useState(initialState);
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    valid: false,
    info: { error: false, msg: null },
  });

  const clearInputs = () => {
    const clearedInputs = {};
    Object.keys(inputs).forEach(k => (clearedInputs[k] = ""));
    setInputs(clearedInputs);
  };

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      clearInputs();
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };
  const handleInputChange = e => {
    e.persist();
    const newInputs = {
      ...inputs,
      [e.target.name]: e.target.value,
    };
    setInputs(newInputs);

    const isValid = validateTouched(newInputs) && validateEmail(newInputs);
    setStatus({
      submitted: false,
      submitting: false,
      valid: isValid,
      info: { error: false, msg: null },
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    setStatus(prevStatus => ({ ...prevStatus, submitting: true }));
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeUrl({ "form-name": "contact", ...inputs }),
    })
      .then(() => {
        handleServerResponse(
          true,
          "Thank you, your message has been submitted."
        );
      })
      .catch(error => {
        handleServerResponse(false, error.message);
      });
  };

  return [inputs, status, handleInputChange, handleSubmit];
}
