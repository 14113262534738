import React from "react";
import PropTypes from "prop-types";
import { CardWrapper, CardText, CardFooter, CardTitles } from "./style";
import { animated } from "react-spring";

function PressComment({ children, logo, author, date, ...other }) {
  return (
    <CardWrapper {...other}>
      <CardText>{children}</CardText>
      <CardFooter>
        {logo}
        <CardTitles>
          <p>{author}</p>
          <p>{date}</p>
        </CardTitles>
      </CardFooter>
    </CardWrapper>
  );
}

PressComment.propTypes = {
  children: PropTypes.string.isRequired,
  logo: PropTypes.node.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default animated(PressComment);
