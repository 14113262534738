import styled from "styled-components";
import ScrollIndicator from "components/atoms/ScrollIndicator";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${({
    theme: {
      layout: { sideMarginInnerDesktop, sideMarginOuterDesktop },
    },
  }) => `0 ${sideMarginOuterDesktop} 0 ${sideMarginInnerDesktop}`};
  @media (max-width: 768px) {
    padding: ${({
      theme: {
        layout: { sideMarginInnerMobile, sideMarginOuterMobile },
      },
    }) => `0 ${sideMarginOuterMobile} 0 ${sideMarginInnerMobile}`};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextContainer = styled.div`
  width: calc(50% - ${props => props.theme.layout.sideMarginOuterDesktop});
  min-width: 9rem;
  min-height: 14.15rem;
  max-height: 45%;

  button {
    margin-top: 2.5rem;
  }
`;

export const ScrollIndicatorStyled = styled(ScrollIndicator)`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.5s ease-out;
  position: absolute;
  top: calc(100vh - 8rem);
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
`;
